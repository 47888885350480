
import moment from 'moment';
import xss from 'xss';
import _ from 'lodash';
import Vue from 'vue';
// @ts-ignore
import vueQr from 'vue-qr';
import NewsType from './newsType';
import NewsItem from './component/NewsItem.vue';
import RelatedNew from './component/RelatedNew.vue';
import JuMap from '~/components/Map/index.vue';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import autoAddLink from '~/util/links';
import {
  getWeekStr,
  getTodayDate,
  decryptAndReplace,
  getContent,
  MINI_HOST,
  OSS_BUCKET_URL,
  removeEmptyTags,
} from '~/util/common';
import FairTag from '~/components/Goods/fairTag.vue';
import MediaBox from '~/components/MediaBox/index.vue';
import ApplyBoothCard from '~/pages/booth/components/card.vue';
import { TraceCode } from '~/util/trace';
import { AxiosResponse } from 'axios';
import { blackList } from '~/util/blackFair';

// let componentString = '';
// const compileFunction = new Function('Vue', 'return ' + componentString);
// const { render } = compileFunction(Vue);
// const NewsComponent = {
//   render,
// };
let templateContentStr = '13';

export default Vue.extend({
  components: {
    // NewsComponent,
    vueQr,
    FairTag,
    MediaBox,
    NewsItem,
    ApplyBoothCard,
    RelatedNew,
    JuMap,
  },
  filters: {
    lunchTime(data: any) {
      const startTime = moment(data.startTime).format('YYYY年MM月DD日');
      const endTime = moment(data.endTime).format('MM月DD日');

      return `${startTime}-${endTime}`;
    },
    description(item: Record<string, any>): any {
      const content = item.description || item.content;
      if (!_.isString(content)) return;

      const reg = /<[^>]+>/gi;
      let contentStr = content || '';
      const description =
        (contentStr ?? '') != ''
          ? contentStr
              ?.replace(reg, '')
              ?.replace(/&nbsp;/g, '')
              .trim()
          : '';
      return description?.slice(0, 100) + '...';
    },
  },
  async asyncData(ctx) {
    try {
      const lang = ctx.query.lang;
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';
      const { id } = ctx.params;

      const requestList = [
        ctx.$axios.get(`/api/exhibition/9212`), // 展会详情
        ctx.$axios.post(`/api/newsApi/get`, { id }), // 资讯详情
        // ctx.$axios.get(`/ossApi/InnerChainInfo/key.json`),
        ctx.$axios.get(`/ossApi/newsContent/${id}.html`), // timestamp=${Date.now()}
      ];
      const [fairDetailRes, detailRes, bodyRes] = (await Promise.all(
        requestList
      )) as any[];
      // 展会详情
      let fairDetail: Record<string, any> = {};
      if (fairDetailRes.code === ResponseCode.SUCCESS) {
        fairDetail = fairDetailRes.data;
      } else {
        return ctx.error({
          message: '展会不存在！',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }

      let fair = {};
      let tag = [];
      if (!_.isEmpty(fairDetail)) {
        const res: any[] = await Promise.all([
          ctx.$axios.post('/api/exhibition/list', {
            locale: fairDetail.locale || 'cn',
            selectTimeType: 1,
            industryId: _.get(fairDetail, 'industryId', null),
            pageNum: 1,
            pageSize: 12,
          }),
          ctx.$axios.post('/api/specialTopicApi/resourceId', {
            type: 1,
            resourceId: id,
          }),
          ctx.$axios.post(`/api/newsApi/getNewsView/${id}`),
        ]);
        const [fairRes, tagRes, viewRes] = res;

        if (fairRes.code === ResponseCode.SUCCESS) {
          fair = fairRes.data;
        }

        if (tagRes.code === ResponseCode.SUCCESS) {
          tag = tagRes.data;
        }

        if (viewRes.code === ResponseCode.SUCCESS) {
          fairDetail.views = viewRes.data.viewCount;
        }
      }

      let newsDetail: any = {};
      // 资讯详情
      if (detailRes.code === ResponseCode.SUCCESS) {
        newsDetail = detailRes.data;
      }

      if (bodyRes) {
        newsDetail.content = bodyRes;
      }

      templateContentStr = bodyRes;
      // componentString = bodyRes

      let dayNumber = 0; // 计算两个日期之间的天数
      let startDate = moment(fairDetail.startTime);
      let endDate = moment(fairDetail.endTime);
      dayNumber = endDate.diff(startDate, 'days');

      let fairDateList: any = [];

      function getDateList(startDate: any, endDate: any) {
        // let currentDate: any = moment(startDate);
        // let dateList: any[] = [];

        // while (
        //   currentDate.isBefore(endDate) ||
        //   currentDate.isSame(endDate, 'day')
        // ) {
        //   // dateList.push(currentDate.clone());
        //   dateList.push({
        //     day: `${currentDate.format('MM')}月${currentDate.format('MM')}日`,
        //     week: `${getWeekStr(moment(currentDate).day())}`,
        //   });
        //   currentDate.add(1, 'days');
        // }

        // return dateList;
        let dates: any = [];
        let currentDate = moment(startDate);

        while (currentDate <= endDate) {
          dates.push({
            day: currentDate.format('MM月DD日'),
            week: `${getWeekStr(moment(currentDate).day())}`,
          });
          currentDate = currentDate.add(1, 'days');
        }

        return dates;
      }
      fairDateList = getDateList(startDate, endDate);

      let timeSliceTypeOptions = {
        0: '全天',
        1: '上午',
        2: '下午',
        3: '夜间',
      };

      let year = getTodayDate().slice(0, 4);
      let descNum = Math.floor(Math.random() * (3 - 1 + 1)) + 1; // 导语随机数 1-3（随机展示导语 ）
      return {
        descNum,
        year,
        timeSliceTypeOptions,
        fairDetail,
        newsDetail,
        dayNumber,
        fairDateList,
        attribution: `&copy; <span>${
          _.get(fairDetail, 'pavilionInfo.pavilionName', null) ||
          _.get(fairDetail, 'pavilionInfo.pavilionEnglishName', null)
        }</span>`,
        center: [
          _.get(fairDetail, 'pavilionInfo.latitude', 0),
          _.get(fairDetail, 'pavilionInfo.longitude', 0),
        ],
        fair,
        tag,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  template: `<div class="content-tem">1001 ${templateContentStr}</div>`, //'<div class="content-tem">1001</div>',
  data(): Record<string, any> {
    return {
      detail: {},
      NewsType,
      MINI_HOST,
      OSS_BUCKET_URL,
      banner: [],
      dialogShow: false,
      dialogTitle: '',
      dialogImg: '',
      blackList,
    };
  },
  computed: {
    pavilionPicture(): string[] {
      const val = _.get(this.detail, 'pavilionInfo.picture', '');
      try {
        return val.split(';');
      } catch (e) {
        return [val];
      }
    },
    photos(): any[] {
      try {
        const newArr: any[] = [];
        if (this.detail.photos) {
          this.detail.photos.slice(0, 2).map((item: { resourceUrl: any }) => {
            newArr.push(item.resourceUrl);
          });
        }
        return newArr;
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return this.detail.pic;
      }
    },
    fairId(): number | undefined {
      const { exhibitionList } = this.detail;
      let id;
      if (!exhibitionList) return id;
      try {
        id = exhibitionList[0]?.id;
      } catch (error) {
        id = null;
        console.error(error);
      }
      return id;
    },
  },
  created() {
    if (process.client) {
      this.detail.content = decryptAndReplace(
        this.detail.content,
        this.detail.informationSecret
      );
      this.fetchLinks();
    }
  },
  mounted() {},
  methods: {
    moment,
    xss,
    getContent,
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },

    async fetchLinks() {
      const { content, newsName } = this.detail;
      const linksRes = (await this.$axios.get(
        `/ossApi/InnerChainInfo/key.json`
      )) as any[];

      const html = removeEmptyTags(getContent(content));

      this.detail.content = await autoAddLink(html, linksRes, newsName);
    },
    handleShowMini() {
      this.dialogShow = true;
      this.dialogTitle = '扫码打开小程序';
      this.dialogImg = require('~/assets/images/juzhan-mini.jpg');
    },

    // isLogin
    shouldLogin() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
      }
      return !!access_token;
    },
    // TODO 关注报错
    async handleAttention() {
      if (this.shouldLogin()) {
        const res: any = await this.$axios.get(
          `/api/news/attention/${this.$route.params.id}`
        );
        if (res.code === ResponseCode.SUCCESS) {
          this.detail.focus = !this.detail.focus;
          if (this.detail.focus) {
            this.$message.success('关注成功.');
          } else {
            this.$message.success('取消关注成功.');
          }
        }
      }
    },
    // 分享到QQ空间
    shareToQzone(event: any) {
      event.preventDefault();
      const { newsName } = this.detail;
      let _shareUrl =
        'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?';
      _shareUrl += 'url=' + encodeURIComponent(document.location.href); // 参数url设置分享的内容链接|默认当前页location
      _shareUrl += '&showcount=1'; // 参数showcount是否显示分享总数,显示：'1'，不显示：'0'，默认不显示
      _shareUrl +=
        '&desc=' +
        encodeURIComponent(
          this.detail.description ||
            '聚展网是国内专业国际展会一站式服务平台，帮助企业便捷参加全球展会，国际展会，企业首选的参展服务平台，公司已带领上千家企业出国参展，足迹遍布欧、美、亚、非、澳五大洲主流会展名城，为中国对外出口贸易做出重要贡献'
        ); // 参数desc设置分享的描述，可选参数
      _shareUrl +=
        '&summary=' +
        encodeURIComponent(
          newsName || '聚展网-展会网_国际展会_展会公司_国外展会信息服务平台'
        ); // 参数summary设置分享摘要，可选参数
      _shareUrl += '&title=' + encodeURIComponent(newsName || document.title); // 参数title设置分享标题，可选参数
      _shareUrl += '&site='; // 参数site设置分享来源，可选参数
      _shareUrl += '&pics=' + encodeURIComponent(''); // 参数pics设置分享图片的路径，多张图片以＂|＂隔开，可选参数
      window.open(
        _shareUrl,
        '_blank',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes,width=800,height=600'
      );
    },
    // 分享到qq
    shareToqq(event: any) {
      event.preventDefault();
      let _shareUrl =
        'https://connect.qq.com/widget/shareqq/iframe_index.html?';
      _shareUrl += 'url=' + encodeURIComponent(document.location.href); // 分享的链接
      _shareUrl +=
        '&title=' + encodeURIComponent(this.detail.newsName || document.title); // 分享的标题
      window.open(
        _shareUrl,
        '_blank',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes,width=800,height=600'
      );
    },
    async handleToPage(type: string) {
      if (type === 'booth') {
        await this.$store.commit('user/setAction', 1);
        window.open(`/exhibition/apply/${this.fairId}.html`, '_blank');
      } else if (type === 'ticket') {
        await this.$store.commit('user/setAction', 2);
        window.open(`/ticket/${this.fairId}.html`, '_blank');
      } else if (type === 'periodical') {
        await this.$store.commit('user/setAction', 3);
        window.open(`/periodical/${this.fairId}.html`, '_blank');
      }
    },
  },
  head(): any {
    const { detail } = this.$data;
    const reg = /<[^>]+>/gi;
    let content = _.get(detail, 'content', '');
    const _description = content
      ? content
          ?.replace(reg, '')
          ?.replace(/&nbsp;/g, '')
          .trim()
      : '';
    let keywords = ``;
    let firstExhibition = null;
    if (detail.exhibitionList && detail.exhibitionList.length > 0) {
      firstExhibition = detail.exhibitionList[0];
      keywords = `${firstExhibition.exhibitionName},${
        firstExhibition.exhibitionEnglishName
      },${firstExhibition.area || ''}${detail.industryName || ''}`;
    }
    let exStr =
      firstExhibition?.area && detail.industryName
        ? `${firstExhibition?.area || ''}${detail.industryName || ''}展`
        : '';

    return {
      title: `${detail.newsName}-${exStr}-聚展`,
      meta: [
        // {
        //   name: 'mobile-agent',
        //   'http-equiv': 'mobile-agent',
        //   content: `format=html5; url=https://m.jufair.com/information/${detail.id}.html`,
        // },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: _description.slice(0, 100),
        },
        {
          name: 'og:type',
          content: 'article',
        },
        // {
        //   name: 'og:url',
        //   content: `https://www.jufair.com${this.$route.fullPath}`,
        // },
        {
          name: 'og:title',
          content: detail.newsName,
        },
        {
          name: 'og:release_date',
          content: detail.createTime,
        },
        {
          name: 'og:image',
          content: detail.logo,
        },
        {
          name: 'og:description',
          content: _description.slice(0, 100),
        },
      ],
    };
  },
});
